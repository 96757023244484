import React from "react";
import "./Particle.scss";
import Particles from "react-particles-js";

const Particle = () => {
  return (
    <Particles
      className="Particle"
      params={{
        particles: {
          number: {
            value: 100
          },
          size: {
            value: 3
          },
          interactivity: {
            events: {
              onresize: {
                enable: true,
                density_auto: true,
              }
            }
          }
        }
      }}
    />
  );
};

export default Particle;
