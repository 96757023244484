import React from "react";
import "./MainName.scss";
import LogoLinkedin from "react-ionicons/lib/LogoLinkedin";
import LogoTwitter from "react-ionicons/lib/LogoTwitter";

const MainName = () => {
  return (
    <div className="MainName">
      <div className="shape"></div>
      <div className="content">
        <span className="title">Tatianna Nieves</span>
        <span className="subtitle">QA Engineer - Testing Trainer</span>
        <div className="contact">
          <a
            href={"https://www.twitter.com/NievesTatianna"}
            target="blank"
            className="item"
          >
            <span className="text">@NievesTatianna</span>
            <LogoTwitter fontSize="25px" color="#CCC" />
          </a>
          <a
            href={"https://www.linkedin.com/in/tatiannanieves"}
            target="blank"
            className="item"
          >
            <span className="text">in/tatiannanieves</span>
            <LogoLinkedin fontSize="25px" color="#CCC" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MainName;
