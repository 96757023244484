import React from "react";

import "./App.css";
import Particle from "./components/Particle/Particle";
import MainName from "./components/MainName/MainName";

const App = () => {
  return (
    <div className="App">
      <MainName />
      <Particle />
    </div>
  );
};

export default App;
